import Vue from 'vue';
import Vuex, { Store } from 'vuex';
import { getModule } from 'vuex-module-decorators';
import MeetingStoreModule from './meeting';

Vue.use(Vuex);
const store = new Vuex.Store({
	modules: {
		meeting: MeetingStoreModule
	}
});
export default store;
export const MeetingStore: MeetingStoreModule = getModule(MeetingStoreModule, store);
