import { WorkingPeriodResource, MeetingLog } from '@/classes/WorkingPeriod.resource';
import { TaskResource } from '@/classes/Task.resource';
import { MeetingConfig } from '@/classes/WorkingPeriod.resource';
import { MeetingParticipant } from '@/classes/MeetingParticipant.resource';
import { TaskAssignment } from '@/classes/TaskAssignments.resource';
import { SelectItemOption, ColorPallet } from '@/classes/base/elements';
import { MeetingStore } from '@/store';
import { LevelFormat, convertInchesToTwip, ExternalHyperlink, UnderlineType, Document, Paragraph, Packer, TextRun, HeadingLevel, AlignmentType, ParagraphChild } from 'docx';
import * as FileSaver from 'file-saver';
import { GoogleSpreadsheet, GoogleSpreadsheetWorksheet } from 'google-spreadsheet';
import * as CREDENTIALS from '../../meeting-navi-key.json';

export function calculateSwitchValues(tasks: TaskResource[]): number[] {
	let taskBreakPoint;
	const switchValues: number[] = [];
	let next = 0;
	let secondsNeeded = 0; //total length
	tasks.forEach((task) => (secondsNeeded += task.secondsNeeded)); // calculating total seconds

	if (tasks.length == 1) {
		switchValues.push(100);
		return switchValues;
	} else {
		for (let i = 0; i < tasks.length - 1; ) {
			// 3 points - 2 breakpoints
			taskBreakPoint = (tasks[i].secondsNeeded * 100) / secondsNeeded; //first taskBP
			next = next + taskBreakPoint;
			switchValues.push(next);
			i++;
		}
		return switchValues;
	}
}

export function cleanLocalStorage(): void {
	localStorage.removeItem('MeetingTimer-MeetingsConfig');
	localStorage.removeItem('MeetingTimer-GlobalId');
	localStorage.removeItem('MeetingTimer-Notification');
	localStorage.removeItem('MeetingTimer-ClientId');
	localStorage.removeItem('MeetingTimer-Draft');
}

export function copyInviteText(inviteMeet: WorkingPeriodResource): void {
	const firstTextPart =
		'Hello,\nyou are kindly invited to a meeting on ' +
		new Date(inviteMeet.meetDay).toLocaleDateString('de-DE', {
			day: '2-digit',
			month: '2-digit',
			year: 'numeric'
		}) +
		'\nfrom ' +
		inviteMeet.meetTime +
		' to ' +
		inviteMeet.possibleEndTime +
		"  o'clock.\n We meet at: " +
		inviteMeet.place +
		'.\n' +
		inviteMeet.title +
		'\n';
	const taskArray: string[] = [];
	for (let n = 0; n < inviteMeet.tasks.length; ) {
		const agendaTitle = String(n + 1) + '. Agenda Item:' + inviteMeet.tasks[n].title;
		const goal = 'Goal:' + inviteMeet.tasks[n].goal;
		taskArray.push(agendaTitle);
		taskArray.push(goal);
		n++;
	}
	const list = taskArray.join('\n');
	const final = firstTextPart + list + '\n Your meeting-navigator';
	navigator.clipboard.writeText(final);
}

export function createDocx(workingPeriod: WorkingPeriodResource): void {
	const meetParticipantsNames: string[] = [];
	const meetingExternalNames: string[] = [];

	let instance = 1;

	if (workingPeriod) {
		workingPeriod.participants.forEach((person) => {
			if (person.roles.indexOf('Participant') > -1) {
				meetParticipantsNames.push(' ' + person.name);
			}
		});
		workingPeriod.participants.forEach((person) => {
			if (person.roles.indexOf('External') > -1) {
				meetingExternalNames.push(' ' + person.name);
			}
		});
	}
	const listReferencesNumber = workingPeriod.orderedListCount * (workingPeriod.participants.length + 1);
	const numberReferenceConfig: any[] = [];
	for (let instanceStep = 1; instanceStep <= listReferencesNumber; instanceStep++) {
		numberReferenceConfig.push({
			reference: 'decimal-numbering' + instanceStep,
			levels: [
				{
					level: 0,
					format: LevelFormat.DECIMAL,
					text: '%1.',
					alignment: AlignmentType.START,
					style: {
						paragraph: {
							indent: { left: convertInchesToTwip(1), hanging: convertInchesToTwip(0.68) }
						}
					}
				},
				{
					level: 1,
					format: LevelFormat.DECIMAL,
					text: '%2.',
					alignment: AlignmentType.START,
					style: {
						paragraph: {
							indent: { left: convertInchesToTwip(1), hanging: convertInchesToTwip(0.25) }
						}
					}
				}
			]
		});
	}
	class DocumentCreator {
		// tslint:disable-next-line: typedef
		public create([workingPeriod]: WorkingPeriodResource[]): Document {
			const document = new Document({
				numbering: {
					config: numberReferenceConfig
				},
				styles: {
					default: {
						heading1: {
							run: {
								font: 'Arial',
								size: 40,
								bold: true,
								color: '000000'
							}
						},
						heading2: {
							run: {
								font: 'Arial',
								size: 30,
								bold: false,
								color: '000000'
							}
						}
					},
					paragraphStyles: [
						{
							id: 'normalPara',
							name: 'Normal Para',
							basedOn: 'Normal',
							next: 'Normal',
							quickFormat: true,
							run: {
								font: 'Calibri',
								size: 26,
								bold: true
							}
						}
					]
				},
				sections: [
					{
						children: [
							this.createMeetHeader(workingPeriod.title),
							new Paragraph({}),
							this.createMeetInfoSection(workingPeriod),
							new Paragraph({}),
							...workingPeriod.tasks
								.map((task: TaskResource) => {
									const arr: any[] = [];
									//Title
									arr.push(this.createTaskHeader(task.id, task.title));
									//Goal
									arr.push(this.createTaskGoal(task.goal));
									//Tasks bullet list
									if (task.assignments.length > 0) {
										task.assignments.forEach((assignment: TaskAssignment) => {
											if (assignment.vHtmlObject) {
												const Pars1: Paragraph[] = this.createParagraphsFromHtml(assignment);
												Pars1.forEach((par: Paragraph) => {
													arr.push(par);
												});
											}
										});
									} else {
										arr.push(
											new Paragraph({
												alignment: AlignmentType.LEFT,
												children: [
													new TextRun({
														text: '\t(no entries)',
														bold: false,
														size: 22,
														font: 'Arial'
													})
												]
											})
										);
									}
									arr.push(new Paragraph({}));
									return arr;
								})
								.reduce((prev, curr) => prev.concat(curr), []),

							this.createTodoHeading('To-Dos'),
							// Tasks for everyone
							new Paragraph({}),
							new Paragraph({
								children: [
									new TextRun({
										text: 'Everybody',
										//color: '666666',
										size: 22,
										font: 'Arial'
									}),
									new TextRun({
										break: 1
									})
								]
							}),

							...workingPeriod.tasks
								.map((task: TaskResource) => {
									const arr: any[] = [];
									if (task.assignments.length > 0) {
										task.assignments.forEach((assignment: TaskAssignment) => {
											if (assignment.vHtmlObject && assignment.deadline && !assignment.responsibleForAssignment) {
												const Pars2: Paragraph[] = this.createParagraphsFromHtml(assignment, false);

												Pars2.forEach((par: Paragraph) => {
													arr.push(par);
												});
											}
										});
									}
									arr.push(new Paragraph({}));
									return arr;
								})
								.reduce((prev, curr) => prev.concat(curr), []),

							new Paragraph({}),
							...workingPeriod.participants
								.map((participant: MeetingParticipant) => {
									const arr: any[] = [];
									arr.push(new Paragraph({}));
									arr.push(
										new Paragraph({
											children: [
												new TextRun({
													text: participant.name,
													//color: '666666',
													size: 22,
													font: 'Arial'
												}),
												new TextRun({
													break: 1
												})
											]
										})
									),
										this.createPersonTodos(participant).forEach((todo) => {
											const Pars3: Paragraph[] = this.createParagraphsFromHtml(todo, false);

											Pars3.forEach((par: Paragraph) => {
												arr.push(par);
											});
										});
									arr.push(new Paragraph({}));
									return arr;
								})
								.reduce((prev, curr) => prev.concat(curr), []),
							new Paragraph({}),
							this.createMeetFooter()
						]
					}
				]
			});
			return document;
		}

		public createTodoHeading(text: string): Paragraph {
			return new Paragraph({
				text: text,
				heading: HeadingLevel.HEADING_1,
				alignment: AlignmentType.CENTER
			});
		}

		public createTaskGoal(taskGoal: string): Paragraph {
			return new Paragraph({
				children: [
					new TextRun({
						text: taskGoal,
						color: '666666',
						size: 22,
						font: 'Arial'
					}),
					new TextRun({
						break: 1
					})
				]
			});
		}

		public createTaskHeader(taskId: number, taskTitle: string): Paragraph {
			return new Paragraph({
				text: taskId + 1 + '. ' + taskTitle,
				heading: HeadingLevel.HEADING_2
			});
		}

		public createMeetHeader(meetTitle: string): Paragraph {
			return new Paragraph({
				text: meetTitle,
				heading: HeadingLevel.HEADING_1,
				alignment: AlignmentType.CENTER
			});
		}

		public createMeetFooter(): Paragraph {
			return new Paragraph({
				alignment: AlignmentType.CENTER,
				children: [
					new TextRun({
						text: 'This report was automatically generated by',
						bold: false,
						size: 18,
						font: 'Arial'
					}),
					new TextRun({
						break: 1
					}),
					new ExternalHyperlink({
						children: [
							new TextRun({
								text: 'www.meeting-navigator.com',
								style: 'Hyperlink',
								size: 18,
								font: 'Arial'
							})
						],
						link: 'https://meetingnavi.medieninformatik-studium.de/'
					})
				]
			});
		}

		public createPersonTodos(person: MeetingParticipant): any[] {
			const personToDos: any[] = [];
			if (workingPeriod) {
				workingPeriod.tasks.forEach((task) => {
					task.assignments.forEach((assignment) => {
						if (assignment.type === 'task-todo' && assignment.responsibleForAssignment && assignment.responsibleForAssignment.length > 0) {
							if (assignment.responsibleForAssignment && assignment.responsibleForAssignment.length > 0)
								assignment.responsibleForAssignment.forEach((pers) => {
									if (String(pers.name) === String(person.name)) {
										const newAssignment = {
											taskId: task.id,
											taskTitle: task.title,
											type: assignment.type,
											responsibleForAssignment: assignment.responsibleForAssignment,
											content: assignment.content,
											deadline: '',
											vHtmlObject: []
										};
										if (assignment.deadline) newAssignment.deadline = assignment.deadline;
										if (assignment.vHtmlObject) newAssignment.vHtmlObject = assignment.vHtmlObject;
										personToDos.push(newAssignment);
									}
								});
						}
					});
				});
			}

			return personToDos;
		}

		public createParagraphsFromHtml(assignment: TaskAssignment, includeFooter = true): Paragraph[] {
			const paragraphs: Paragraph[] = [];

			// get participants
			const participants: string[] = [];
			if (assignment.responsibleForAssignment && assignment.responsibleForAssignment.length > 0) {
				assignment.responsibleForAssignment.forEach((person) => {
					participants.push(person.name);
				});
			}

			if (assignment.vHtmlObject) {
				assignment.vHtmlObject.forEach((item: any) => {
					if (item.tag === 'p') {
						let paragraphChildren: any[] = [];
						if (assignment.vHtmlObject) {
							paragraphChildren = this.createTextRuns(item.content, paragraphChildren);
						}
						const paragraph = new Paragraph({
							bullet: {
								level: 0
							},
							children: paragraphChildren
						});
						paragraphs.push(paragraph);
					} else if ((item.tag === 'ul' || item.tag === 'ol') && item.children) {
						item.children.forEach((li: any) => {
							let paragraphChildren: any[] = [];
							paragraphChildren = this.createTextRuns(li.content, paragraphChildren);
							if (item.tag === 'ol') {
								const paragraph = new Paragraph({
									numbering: {
										reference: 'decimal-numbering' + instance,
										level: 1,
										instance: instance
									},
									children: paragraphChildren
								});
								paragraphs.push(paragraph);
							} else {
								const paragraph = new Paragraph({
									bullet: {
										level: 1 // Adjust the level as needed
									},
									children: paragraphChildren
								});
								paragraphs.push(paragraph);
							}
						});
						if (item.tag === 'ol') {
							instance++;
						}
					}
				});
			}
			if (includeFooter) {
				paragraphs.push(
					new Paragraph({
						alignment: AlignmentType.START,
						children: [
							new TextRun({
								break: 1
							}),
							new TextRun({
								text: '\t',
								bold: false,
								color: '666666',
								size: 22,
								font: 'Arial'
							}),
							new TextRun({
								text: assignment.deadline
									? 'due ' +
									  new Date(assignment.deadline).toLocaleDateString('de-DE', {
											day: '2-digit',
											month: '2-digit',
											year: 'numeric'
									  }) +
									  '\t'
									: '',
								bold: false,
								color: '666666',
								size: 22,
								font: 'Arial'
							}),
							new TextRun({
								text: participants.join(', '),
								bold: false,
								color: '666666',
								size: 22,
								font: 'Arial'
							}),
							new TextRun({
								break: assignment.deadline || participants.length > 0 ? 1 : undefined
							})
						]
					})
				);
			}

			return paragraphs;
		}

		createTextRuns(vHtmlArray: any[], paragraphChildren: any[] = [], parentStyles: any = {}) {
			vHtmlArray.forEach((item: any) => {
				const currentStyles = { ...parentStyles };

				if (item.tag) {
					// Update styles based on the current tag
					if (item.tag === 'strong') {
						currentStyles.bold = true;
					} else if (item.tag === 'em') {
						currentStyles.italic = true;
					} else if (item.tag === 'u') {
						currentStyles.underline = true;
					}

					// Handle nested content
					if (item.content) {
						this.createTextRuns(item.content, paragraphChildren, currentStyles);
					}
				} else {
					const spaceBefore = currentStyles.bold || currentStyles.italic || currentStyles.underline ? ' ' : '';
					const spaceAfter = spaceBefore || (item.content && item.content.trim().length > 0) ? ' ' : '';

					const textRun = new TextRun({
						text: (item.content || '') + spaceAfter,
						bold: currentStyles.bold,
						italics: currentStyles.italic,
						underline: currentStyles.underline
							? {
									type: UnderlineType.SINGLE,
									color: '999999'
							  }
							: undefined,
						size: 22,
						font: 'Arial'
					});

					paragraphChildren.push(textRun);
				}
			});

			return paragraphChildren;
		}

		public createEveryoneBulletAssignmentList(assignment: TaskAssignment): Paragraph {
			return new Paragraph({
				bullet: {
					level: 0
				},
				children: [
					new TextRun({
						text: assignment.content,
						bold: false,
						size: 22,
						font: 'Arial'
					}),
					new TextRun({
						break: 1
					}),

					new TextRun({
						text: assignment.deadline
							? new Date(assignment.deadline).toLocaleDateString('de-DE', {
									day: '2-digit',
									month: '2-digit',
									year: 'numeric'
							  })
							: '',
						bold: false,
						color: '666666',
						size: 22,
						font: 'Arial'
					}),
					new TextRun({
						break: 1
					})
				]
			});
		}
		public createMeetInfoSection(workingPeriod: WorkingPeriodResource): Paragraph {
			let endTime;
			let endTimeContainer;

			if (workingPeriod.meetTime.length > 0) {
				const wpTime = workingPeriod.meetTime.split(':');
				const timeAsMinutes = +wpTime[0] * 60 + +wpTime[1];
				endTimeContainer = timeAsMinutes + Number(getEntireTime(workingPeriod));
				const hours = Math.floor(endTimeContainer / 60);
				const minutes = endTimeContainer % 60;
				if (hours == 0 && minutes == 0) {
					endTime = '00:00';
				}
				if (hours > 23) {
					const newHours = (hours % 23) - 1;
					endTime = (newHours < 10 ? '0' : '') + newHours + ':' + (minutes < 10 ? '0' : '') + minutes;
				}
				endTime = (hours < 10 ? '0' : '') + hours + ':' + (minutes < 10 ? '0' : '') + minutes;
			}
			return new Paragraph({
				alignment: AlignmentType.CENTER,
				children: [
					new TextRun({
						text:
							`${new Date(workingPeriod.meetDay).toLocaleDateString('de-DE', {
								day: '2-digit',
								month: '2-digit',
								year: 'numeric'
							})}\t${workingPeriod.meetTime} - ` + endTime,
						bold: false,
						size: 22,
						color: '666666',
						font: 'Arial'
					})
				]
			});
		}
	}
	const date = new Date();
	const dateStr = date.getFullYear() + '-' + ('00' + (date.getMonth() + 1)).slice(-2) + '-' + ('00' + date.getDate()).slice(-2) + ' ' + ('00' + date.getHours()).slice(-2) + '-' + ('00' + date.getMinutes()).slice(-2);
	if (workingPeriod) {
		const exportName = 'Report Meeting ' + workingPeriod.title.substr(0, 20) + ' ' + dateStr;
		const documentCreator = new DocumentCreator();
		const doc = documentCreator.create([workingPeriod]);
		// To export into a .docx file
		Packer.toBlob(doc).then((blob) => {
			FileSaver.saveAs(blob, exportName + '.docx'); // Report Meeting Title 2021-08-08 12-48.docx
		});
	}
}

export async function createLogObject(workingPeriod: WorkingPeriodResource, event: string, tasksOrderChanged?: boolean, meetingEditTime?: number, gapFromPlannedStart?: number): Promise<void> {
	const now = new Date();
	let upcomingMeetings = 0;
	let totalFinishedMeetings = 0;
	const averageSpacePerParticipantName: number[] = [];
	const actualMeetingTasksDuration: number[] = [];
	let externalParticipantsCount = 0;

	workingPeriod.participants.forEach((participant) => {
		let spaceCount = 0;
		spaceCount = participant.name.split(' ').length - 1;
		averageSpacePerParticipantName.push(spaceCount);
		if (participant.roles.includes('External')) externalParticipantsCount++;
	});

	const tasksTitlesLength: number[] = [];
	const tasksGoalsLength: number[] = [];
	const totalTasksDuration: number[] = [];
	const tasksNotesCount: number[] = [];
	let todosWithoutDeadline = 0;
	let todosWithDeadline = 0;
	const nodeadlineTodosResponsibleCount: number[] = [];
	const deadlineTodosResponsibleCount: number[] = [];

	workingPeriod.tasks.forEach((task: TaskResource) => {
		let tasksTitleLength = 0;
		let tasksGoalLength = 0;
		let totalTaskDuration = 0;
		let actualMeetingTaskDuration = 0;
		let taskNotesCount = 0;

		tasksTitleLength = task.title.length;
		tasksGoalLength = task.goal.length;
		totalTaskDuration = task.duration;
		actualMeetingTaskDuration = task.secondsCounted;

		task.assignments.forEach((assignment: TaskAssignment) => {
			let nodeadlineTodoResponsibleCount = 0;
			let deadlineTodoResponsibleCount = 0;
			if (assignment.type === 'task-note') taskNotesCount++;
			if (assignment.type === 'task-todo' && assignment.deadline === undefined) {
				todosWithoutDeadline++;
				if (assignment.responsibleForAssignment) nodeadlineTodoResponsibleCount = assignment.responsibleForAssignment.length;
			}
			if (assignment.type === 'task-todo' && assignment.deadline && assignment.deadline.length > 0) {
				todosWithDeadline++;
				if (assignment.responsibleForAssignment) deadlineTodoResponsibleCount = assignment.responsibleForAssignment.length;
			}
			if (nodeadlineTodoResponsibleCount > 0) nodeadlineTodosResponsibleCount.push(nodeadlineTodoResponsibleCount);
			if (deadlineTodoResponsibleCount > 0) deadlineTodosResponsibleCount.push(deadlineTodoResponsibleCount);
		});

		tasksTitlesLength.push(tasksTitleLength);
		tasksGoalsLength.push(tasksGoalLength);
		totalTasksDuration.push(totalTaskDuration);
		actualMeetingTasksDuration.push(actualMeetingTaskDuration);
		tasksNotesCount.push(taskNotesCount);
	});

	const expression = /(http|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
	const regex = new RegExp(expression);
	const placeIsUrl = workingPeriod.place.match(regex) ? true : false;

	MeetingStore.workingPeriods.forEach((meet: WorkingPeriodResource) => {
		if (new Date(meet.meetDay).getTime() > now.getTime() && meet.status !== 'finished') upcomingMeetings++;
		if (meet.status === 'finished') totalFinishedMeetings++;
	});

	const res: MeetingLog = {
		version: MeetingStore.appVersion,
		clientId: MeetingStore.clientId,
		uuid: workingPeriod.uuid,
		event: event,
		timestamp: Math.floor(new Date(workingPeriod.meetDay).getTime() / 1000),
		upcomingMeetings: upcomingMeetings,
		totalFinishedMeetings: totalFinishedMeetings,
		titleLength: workingPeriod.title.length,
		placeLength: workingPeriod.place.length,
		meetingDate: new Date(workingPeriod.meetDay).toLocaleDateString('de-DE', {
			day: '2-digit',
			month: '2-digit',
			year: 'numeric'
		}), // 01.01.2001
		meetingDay: new Date(workingPeriod.meetDay).toLocaleDateString('en-US', { weekday: 'long' }), //Monday
		meetingTime: workingPeriod.meetTime,
		participantsCount: workingPeriod.participants.length,
		averageSpacePerParticipantName: averageSpacePerParticipantName,
		placeIsUrl: placeIsUrl,
		totalMeetingDuration: workingPeriod.duration, // in min
		totalTasksCount: workingPeriod.tasks.length,
		tasksTitleLength: tasksTitlesLength,
		tasksGoalLength: tasksGoalsLength,
		totalTasksDuration: totalTasksDuration, // in min
		pauseDuration: workingPeriod.pause.duration,
		bufferDuration: workingPeriod.buffer.duration,
		userAgent: navigator.userAgent
	};

	if (event === 'Create' || event === 'Update') {
		res.meetingGapFromToday = (new Date(workingPeriod.meetDay).getTime() - now.getTime()) / 1000;
		res.updateCount = workingPeriod.updateCount;
		if (tasksOrderChanged) res.changedTasksOrder = tasksOrderChanged;
		if (meetingEditTime) res.meetingEditTime = meetingEditTime;
	}

	if (event === 'Finish') {
		res.meetingActualDuration = workingPeriod.secondsCounted;
		res.actualMeetingTasksDuration = actualMeetingTasksDuration;
		res.taskNotesCount = tasksNotesCount;
		res.todosWithoutDeadline = todosWithoutDeadline;
		res.todosWithDeadline = todosWithDeadline;
		res.nodeadlineTodosResponsibleCount = nodeadlineTodosResponsibleCount;
		res.deadlineTodosResponsibleCount = deadlineTodosResponsibleCount;
		res.externalParticipantsCount = externalParticipantsCount;
		if (gapFromPlannedStart) {
			res.gapFromPlannedStart = gapFromPlannedStart;
		}
	}

	const RESPONSES_SHEET_ID = '18uHnO0AsJF1QP2xzCwlNtT6N4ggxk4qc6OCeYDab4GY'; // sheet
	const doc = new GoogleSpreadsheet(RESPONSES_SHEET_ID);

	await doc.useServiceAccountAuth({
		client_email: CREDENTIALS.client_email,
		private_key: CREDENTIALS.private_key
	});

	await doc.loadInfo();

	// Index of the sheet
	const sheet: GoogleSpreadsheetWorksheet = doc.sheetsByIndex[0]; // stage

	const row = {
		version: res.version,
		clientId: res.clientId,
		uuid: res.uuid,
		event: res.event,
		timestamp: res.timestamp,
		upcomingMeetings: res.upcomingMeetings,
		totalFinishedMeetings: res.totalFinishedMeetings,
		titleLength: res.titleLength,
		placeLength: res.placeLength,
		meetingDate: res.meetingDate,
		meetingDay: res.meetingDay,
		meetingTime: res.meetingTime,
		participantsCount: res.participantsCount,
		averageSpacePerParticipantName: res.averageSpacePerParticipantName.toString(),
		placeIsUrl: res.placeIsUrl,
		totalMeetingDuration: res.totalMeetingDuration,
		totalTasksCount: res.totalTasksCount,
		tasksTitleLength: res.tasksTitleLength.toString(),
		tasksGoalLength: res.tasksGoalLength.toString(),
		totalTasksDuration: res.totalTasksDuration.toString(),
		pauseDuration: res.pauseDuration,
		bufferDuration: res.bufferDuration,
		userAgent: res.userAgent,
		updateCount: res.updateCount ? res.updateCount : '',
		changedTasksOrder: res.changedTasksOrder ? res.changedTasksOrder : '',
		meetingGapFromToday: res.meetingGapFromToday ? res.meetingGapFromToday : '',
		gapFromPlannedStart: res.gapFromPlannedStart ? res.gapFromPlannedStart : '',
		meetingActualDuration: res.meetingActualDuration ? res.meetingActualDuration : '',
		actualMeetingTasksDuration: res.actualMeetingTasksDuration ? res.actualMeetingTasksDuration.toString() : '',
		taskNotesCount: res.taskNotesCount ? res.taskNotesCount.toString() : '',
		todosWithoutDeadline: res.todosWithoutDeadline ? res.todosWithoutDeadline : '',
		todosWithDeadline: res.todosWithDeadline ? res.todosWithDeadline : '',
		nodeadlineTodosResponsibleCount: res.nodeadlineTodosResponsibleCount ? res.nodeadlineTodosResponsibleCount.toString() : '',
		deadlineTodosResponsibleCount: res.deadlineTodosResponsibleCount ? res.deadlineTodosResponsibleCount.toString() : '',
		externalParticipantsCount: res.externalParticipantsCount ? res.externalParticipantsCount : '',
		meetingEditTime: res.meetingEditTime ? res.meetingEditTime : ''
	};
	await sheet.addRow(row);

	//return res;
}

export function downloadMeetConfigAsJson(workingPeriods: WorkingPeriodResource[], globalId: number, clientId: string): void {
	const config: MeetingConfig = {
		workingPeriods: workingPeriods,
		globalID: globalId,
		clientId: clientId
	};
	const date = new Date();
	const dateStr = date.getFullYear() + '-' + ('00' + (date.getMonth() + 1)).slice(-2) + '-' + ('00' + date.getDate()).slice(-2) + ' ' + ('00' + date.getHours()).slice(-2) + '-' + ('00' + date.getMinutes()).slice(-2);
	const exportName = 'meeting-navigator-data ' + dateStr;
	const dataStr = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(config));
	const downloadAnchorNode = document.createElement('a');
	downloadAnchorNode.setAttribute('href', dataStr);
	downloadAnchorNode.setAttribute('download', exportName + '.json');
	document.body.appendChild(downloadAnchorNode); // required for firefox
	downloadAnchorNode.click();
	downloadAnchorNode.remove();
}

export function getAssignmentOptions(assignment: TaskAssignment): SelectItemOption[] {
	const options: SelectItemOption[] = [];
	if (assignment) {
		options.push(
			{
				name: 'edit',
				description: 'Edit',
				icon: {
					name: 'pencil'
				}
			},
			{
				name: 'delete',
				description: 'Delete',
				icon: {
					name: 'close'
				}
			}
		);
	}
	return options;
}

export function getColorPallets(): ColorPallet[] {
	const pallet1 = ['#3A69BC', '#D83976', '#F2BF00', '#7EA83B', '#E36C1F'];
	const pallet2 = ['#3A69BC', '#95C3ED'];
	//const pallet3 = ['#cbd5e1', '#94a3b8', '#e2e8f0'];
	const pallets = [
		{
			id: 1,
			colors: pallet1
		},
		{
			id: 2,
			colors: pallet2
		}
		/*{
			id: 3,
			colors: pallet3
		}*/
	];
	return pallets;
}

export function getEntireTime(workingPeriod: WorkingPeriodResource): number {
	let entireTime = 0;
	let discussionTime = 0;
	workingPeriod.tasks.forEach((task) => (discussionTime = discussionTime + task.startSecondsNeeded));
	if (discussionTime > 0) {
		entireTime = entireTime + discussionTime;
	}
	if (workingPeriod.pause.secondsNeeded > 0) {
		entireTime = entireTime + workingPeriod.pause.secondsNeeded;
	}
	if (workingPeriod.buffer.secondsNeeded > 0) {
		entireTime = entireTime + workingPeriod.buffer.secondsNeeded;
	}
	const minutes = Math.floor(entireTime / 60);
	return minutes;
}

export function getUniqueId(length: number): string {
	let id = '';
	const char_list = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	for (let i = 0; i < length; i++) {
		id += char_list.charAt(Math.floor(Math.random() * char_list.length));
	}
	return id;
}

export function getMeetOptions(workingPeriod: WorkingPeriodResource): SelectItemOption[] {
	const options: SelectItemOption[] = [];
	if (workingPeriod) {
		if (workingPeriod.status === 'paused') {
			options.push(
				{
					name: 'open',
					description: 'Open meeting',
					icon: {
						name: 'play'
					}
				},
				{
					name: 'delete',
					description: 'Delete',
					icon: {
						name: 'close'
					}
				}
			);
		}
		if (workingPeriod.status === 'not-started') {
			options.push(
				{
					name: 'open',
					description: 'Open meeting',
					icon: {
						name: 'play'
					}
				},
				{
					name: 'edit',
					description: 'Edit',
					icon: {
						name: 'pencil'
					}
				},
				{
					name: 'invite',
					description: 'Show invitation text',
					icon: {
						name: 'email-arrow-left-outline'
					}
				},
				{
					name: 'template',
					description: 'Use as template',
					icon: {
						name: 'content-copy'
					}
				},
				{
					name: 'delete',
					description: 'Delete',
					icon: {
						name: 'close'
					}
				}
			);
		}
		if (workingPeriod.status === 'finished') {
			options.push(
				{
					name: 'open',
					description: 'Open meeting',
					icon: {
						name: 'play'
					}
				},
				{
					name: 'report',
					description: 'Download Report',
					icon: {
						name: 'tray-arrow-down'
					}
				},
				{
					name: 'template',
					description: 'Use as template',
					icon: {
						name: 'content-copy'
					}
				},
				{
					name: 'delete',
					description: 'Delete',
					icon: {
						name: 'close'
					}
				}
			);
		}
	}
	return options;
}

export function getPercentage(tasks: TaskResource[]): number[] {
	const percentage: number[] = [];
	const switchValues = calculateSwitchValues(tasks);
	const tmp: number[] = [];
	let input;
	tmp.push(0);
	switchValues.forEach((element) => tmp.push(element));
	tmp.push(100); // tmp = [0, 25, 75, 100] length 4
	for (let i = 1; i < tmp.length; ) {
		input = tmp[i] - tmp[i - 1];
		percentage.push(input);
		i++;
	}
	return percentage;
}

export function getPossibleEndTime(workingPeriod: WorkingPeriodResource): string {
	let possibleEndTime = 0;
	if (workingPeriod.meetTime.length > 0) {
		const taskTime = workingPeriod.meetTime.split(':');
		const timeAsMinutes = +taskTime[0] * 60 + +taskTime[1];
		possibleEndTime = timeAsMinutes + Number(getEntireTime(workingPeriod));
		const hours = Math.floor(possibleEndTime / 60);
		const minutes = possibleEndTime % 60;
		if (hours == 0 && minutes == 0) {
			return '00:00';
		}
		if (hours > 23) {
			const newHours = (hours % 23) - 1;
			return (newHours < 10 ? '0' : '') + newHours + ':' + (minutes < 10 ? '0' : '') + minutes;
		}
		return (hours < 10 ? '0' : '') + hours + ':' + (minutes < 10 ? '0' : '') + minutes;
	} else {
		return '';
	}
}

/* export function init(): MeetingConfig {
	let workingPeriods: WorkingPeriodResource[] = [];
	let id = 0;
	if (localStorage.getItem('MeetingTimer-MeetingsConfig')) {
		const localData = localStorage.getItem('MeetingTimer-MeetingsConfig');
		const globalID = localStorage.getItem('MeetingTimer-GlobalId');
		if (localData && globalID) {
			workingPeriods = JSON.parse(localData);
			id = Number(JSON.parse(globalID));
		}
	}
	const config = {
		workingPeriods: workingPeriods,
		globalID: id
	};
	return config;
} */
