import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
	{
		path: '/',
		name: 'Home',
		component: () => import('../views/Overview.vue')
	},
	{
		path: '/about',
		name: 'About',
		component: () => import('../views/About.vue')
	},
	{
		path: '/meeting/:meetingId',
		component: () => import('../views/MeetingView.vue')
	},
	/*{
		path: '/create',
		component: () => import('../views/CreateMeetingForm.vue')
	},*/
	{
		path: '/create',
		component: () => import('../views/CreateMeetingForm.vue'),
		children: [
			{
				path: ':templateId/template',
				component: () => import('../views/CreateMeetingForm.vue')
			},
			{
				path: ':templateId/edit',
				component: () => import('../views/CreateMeetingForm.vue')
			}
		]
	},
	{
		path: '/tutorial',
		name: 'Tutorial',
		component: () => import('../views/Tutorial.vue')
	},
	{
		path: '/imprint',
		name: 'Impressum',
		component: () => import('../views/Imprint.vue')
	},
	{
		path: '/privacy',
		name: 'Privacy',
		component: () => import('../views/PrivacyPolicy.vue')
	}
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
});

/*router.beforeEach((to, from, next) => {
  // Execute Matomo tracking code on route change
  _paq.push(['trackPageView']);
  next();
});*/

export default router;
