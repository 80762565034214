import { WorkingPeriodResource, MeetingConfig } from '@/classes/WorkingPeriod.resource';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { getUniqueId } from '../helper/index';
import store from '@/store';
@Module({ store, namespaced: true, name: 'meeting' })
export default class MeetingStoreModule extends VuexModule {
	private _workingPeriods: WorkingPeriodResource[] = [];
	private _globalId = 1;
	private _version = '1.0';
	//private _uri = 'http://localhost:3000/meetings';
	private _clientId = '';

	public get workingPeriods(): WorkingPeriodResource[] {
		return this._workingPeriods;
	}

	public get globalId(): number {
		return this._globalId;
	}

	public get appVersion(): string {
		return this._version;
	}

	public get clientId(): string {
		return this._clientId;
	}

	@Mutation
	onInit(config: MeetingConfig): void {
		try {
			this._workingPeriods = config.workingPeriods;
			this._globalId = config.globalID;
			this._clientId = config.clientId;
			const client: string = JSON.stringify(this._clientId);
			localStorage.setItem('MeetingTimer-ClientId', client);
		} catch (error) {
			console.log(error);
		}
	}

	@Mutation
	incrementGlobalId(inc: number): void {
		this._globalId += inc;
		const globalId: string = JSON.stringify(this._globalId);
		localStorage.setItem('MeetingTimer-GlobalId', globalId);
	}

	@Mutation
	pushWorkingPeriod(newWorkingPeriod: WorkingPeriodResource): void {
		this._workingPeriods.push(newWorkingPeriod);
		const obj: string = JSON.stringify(this._workingPeriods);
		localStorage.setItem('MeetingTimer-MeetingsConfig', obj);
	}

	@Mutation
	editWorkingPeriod(newWorkingPeriod: WorkingPeriodResource): void {
		const target: WorkingPeriodResource | undefined = this._workingPeriods.find((meet: WorkingPeriodResource): boolean => Number(meet.id) === Number(newWorkingPeriod.id));
		if (target) {
			const index: number = this._workingPeriods.indexOf(target, 0);
			this._workingPeriods[index] = Object.assign({}, newWorkingPeriod);
			const obj: string = JSON.stringify(this._workingPeriods);
			localStorage.setItem('MeetingTimer-MeetingsConfig', obj);
		}
	}

	@Mutation
	removeWorkingPeriod(workingPeriod: WorkingPeriodResource): void {
		const index: number = this._workingPeriods.indexOf(workingPeriod, 0);
		this._workingPeriods.splice(index, 1);
		const obj: string = JSON.stringify(this._workingPeriods);
		localStorage.setItem('MeetingTimer-MeetingsConfig', obj);
		if (this._workingPeriods.length === 0) {
			localStorage.removeItem('MeetingTimer-MeetingsConfig');
			localStorage.removeItem('MeetingTimer-GlobalId');
			location.reload();
		}
	}

	@Mutation
	updateConfig(workingPeriods: WorkingPeriodResource[]): void {
		const obj: string = JSON.stringify(workingPeriods);
		localStorage.setItem('MeetingTimer-MeetingsConfig', obj);
	}

	@Action({ commit: 'onInit' })
	init(): MeetingConfig | undefined {
		//build a config obj for onInit
		try {
			const workingPeriods: string | null = localStorage.getItem('MeetingTimer-MeetingsConfig');
			const globalID: string | null = localStorage.getItem('MeetingTimer-GlobalId');
			const clientId: string | null = localStorage.getItem('MeetingTimer-ClientId');
			const config: MeetingConfig = {
				workingPeriods: workingPeriods ? JSON.parse(workingPeriods) : this._workingPeriods,
				globalID: globalID ? Number(JSON.parse(globalID)) : this._globalId,
				clientId: clientId && clientId.length > 5 ? JSON.parse(clientId) : getUniqueId(20)
			};
			return config;
		} catch (error) {
			console.log(error);
		}
	}

	@Action({ commit: 'incrementGlobalId' })
	incr(): number {
		return 1;
	}

	@Action({ commit: 'pushWorkingPeriod' })
	addWorkingPeriod(newWorkingPeriod: WorkingPeriodResource): WorkingPeriodResource {
		return newWorkingPeriod;
	}

	@Action({ commit: 'editWorkingPeriod' })
	updateWorkingPeriod(newWorkingPeriod: WorkingPeriodResource): WorkingPeriodResource {
		return newWorkingPeriod;
	}

	@Action({ commit: 'removeWorkingPeriod' })
	deleteWorkingPeriod(workingPeriod: WorkingPeriodResource): WorkingPeriodResource {
		return workingPeriod;
	}

	@Action({ commit: 'updateConfig' })
	updateStore(): WorkingPeriodResource[] {
		return this._workingPeriods;
	}
}
